@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.range-input {
  appearance: none;
  background-color: white;
  height: 1px;
  border-radius: 5px;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.range-input::-webkit-slider-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

#fp-nav {
  z-index: 99 !important;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background-color: white !important;
  /* Use !important to ensure override */
}

/* Optional: Style for active and hover states */
#fp-nav ul li .active span,
.fp-slidesNav ul li .active span,
#fp-nav ul li a:hover span,
.fp-slidesNav ul li a:hover span {
  background-color: lightgray !important;
  /* Change color on hover and active states */
}

.vimeo-unmute-button {
  display: none !important;
}



.range-input::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

/* Styles for Internet Explorer/Edge */
.range-input::-ms-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.transition-transform {
  transition: transform 0.7s ease-in-out;
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}



.animated-text {
  display: inline-block;

  font-weight: bold;
  color: white;
  overflow: hidden;
  /* Ensures no overflow while animating */
}

.animated-text span {
  display: inline-block;
  opacity: 0;
  /* Start invisible */
  animation: rise-up 0.3s ease forwards;
  /* Faster entrance animation */
}

@keyframes rise-up {
  0% {
    transform: translateY(20px);
    /* Start from below */
    opacity: 0;
    /* Start invisible */
  }

  100% {
    transform: translateY(0);
    /* End at original position */
    opacity: 1;
    /* End visible */
  }
}

@keyframes fall-down {
  0% {
    transform: translateY(0);
    /* Start at original position */
    opacity: 1;
    /* Start visible */
  }

  100% {
    transform: translateY(20px);
    /* End below */
    opacity: 0;
    /* End invisible */
  }
}




.magnetic-button {
  display: inline-block;
  position: relative;
  transition: transform 0.2s ease-out;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.8rem;
  background-color: transparent;
}

.magnetic-button:hover {
  transition: transform 0.1s ease-out;
}

.magnetic-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.magnetic-button:hover::before {
  transform: translate(-50%, -50%) scale(1.5);
  opacity: 0;
}

.scroll-to-top {
  border-radius: 9999px !important;
}

@media (max-width: 768px) {
  .navbar {
    background-color: black;
  }

  .scroll-to-top {
    right: 20px !important;
  }
}